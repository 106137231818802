import { AuthTokens } from '@/auth';
import { AgdirHttpClient } from '@/http-service';
import { CompanyService } from '@/company-service';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, Observable, startWith, Subject } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AnonymousInvitation, Invitation, InvitationCode } from '../domain/invitation';

const API = 'heimdall/invite';

@Injectable({ providedIn: 'root' })
export class InvitationService {
	reload = new Subject<void>();
	private httpService = inject(AgdirHttpClient);
	private companyService = inject(CompanyService);
	private currentCompanyInvites$ = this.reload.pipe(
		startWith(true),
		switchMap((company) => this.httpService.get<Invitation[]>(`{companyId}/${API}`)),
		shareReplay(1),
	);

	currentCompanyInvitesSignal = toSignal(this.currentCompanyInvites$);

	inviteeStatus(code: InvitationCode): Observable<{ exists: boolean }> {
		return this.httpService.get<{ exists: boolean }>(`${API}/${code}/user-status`);
	}

	getByCode(code: InvitationCode): Observable<Invitation> {
		return this.httpService.get<Invitation>(`${API}/${code}`).pipe(map((invitation) => this.mapDates(invitation)));
	}

	getByCodeAnonymously(code: InvitationCode): Observable<AnonymousInvitation> {
		return this.httpService.get<AnonymousInvitation>(`${API}/${code}/anonymously`);
	}

	async decline(invitation: Invitation): Promise<boolean> {
		return this.httpService.deleteAsync<boolean>(`${API}/${invitation.code}`);
	}

	async delete(invitation: Invitation): Promise<boolean> {
		return this.httpService.deleteAsync<boolean>(`${API}/${invitation.code}/delete`);
	}

	async accept(invitation: Invitation): Promise<boolean> {
		return this.httpService.patchAsync<boolean>(`${API}/${invitation.code}`, {});
	}

	acceptAnonymously(invitationCode: string) {
		return this.httpService.patchAsync<AuthTokens>(`${API}/${invitationCode}/anonymously`, {});
	}

	async sendInviteToNumber(invitation: Partial<Invitation>): Promise<Invitation> {
		const company = invitation.company?.id
			? {
					id: invitation.company.id,
					organizationName: invitation.company?.organizationName,
				}
			: await this.companyService.getCurrentCompanyAsync();
		return this.httpService.postAsync<Invitation>(`{companyId}/${API}`, {
			...invitation,
			company,
		});
	}

	getForCurrentCompany(): Observable<Invitation[]> {
		return this.currentCompanyInvites$;
	}

	async getForCurrentCustomer(): Promise<Invitation[]> {
		const invitations = await this.httpService.getAsync<Invitation[]>(API);
		return invitations
			.map((invitation) => this.mapDates(invitation))
			.sort((a, b) => (b.expiresAt?.getTime() || 0) - (a.expiresAt?.getTime() || 0));
	}

	private mapDates(invite: Invitation): Invitation {
		if (invite.changedAt) {
			invite.changedAt = new Date(invite.changedAt);
		}
		if (invite.changedAt) {
			invite.changedAt = new Date(invite.changedAt);
		}
		if (invite.expiresAt) {
			invite.expiresAt = new Date(invite.expiresAt);
		}
		return invite;
	}
}
